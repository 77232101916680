<template>
  <b-container class="create-account-wizard-liability create-account-wizard-liability-step-01">
    <b-row no-gutters>
      <b-col sm="12" md="5" class="detail-text text-left pt-3 pb-2 px-3 py-md-5 px-md-4 py-lg-5 px-lg-5" align-self="center">
        <h2 class="text-dark">
          {{ $t( translation_path + 'title_category' ) }}
        </h2>
        <i18n :path="translation_path + 'intro_p1'" tag="p" class="text-gray">
          <b-link slot="link" href="https://forum.spirecta.se/t/fordjupad-information-om-skulder/86" target="_blank">{{ $t(translation_path + 'read_more_link') }}</b-link>
        </i18n>
      </b-col>
      <b-col sm="12" md="7" class="accounts_wrapper pt-0 pb-2 px-3 py-md-5 px-md-4 py-lg-5 px-lg-5">
        <b-form-group
          label-class="FormLabel pr-4 text-black"
          label-for="account_title"
          label-cols-lg="2"
          label-align-lg="right"
          required
          :invalid-feedback="invalidAccountTitleFeedback"
          :label="$t(translation_path + 'title_label')"
          >
          <template slot="description" v-if="typeOfAccount">{{ $t(translation_path + 'title_help_text_'+typeOfAccount) }}</template>
          <b-form-input id="account_title" class="rounded-pill"
                        :placeholder="$t(translation_path+'title_placeholder')"
                        :state="$v.account_title.$dirty ? !$v.account_title.$error : null"
                        v-model="account_title"
                        @input="$v.account_title.$touch()"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import { required, minLength } from 'vuelidate/lib/validators'
import axios from 'axios'

const isTitleUnique = getter => function () {
  return this.is_title_unique
}

export default {
  name: 'LiabilityAccountWizardStep01',
  props: ['initialData', 'typeOfAccount', 'isUpdate'],
  data () {
    return {
      accounts: null,
      account_title: null,
      account_id: null,
      account_percentage: 0,
      translation_path: 'assets_liabilities.create_account_wizard.liabilities.step1.',
      is_title_unique: true
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    intro_p2_path () {
      return this.translation_path + (this.currentUser.strict_accounting_mode ? 'intro_p2_account' : 'intro_p2_category')
    },
    invalidAccountTitleFeedback () {
      if (this.$v.account_title.required === false) { return this.$i18n.t(this.translation_path + 'errors.title_required') }
      if (this.$v.account_title.minLength === false) { return this.$i18n.t(this.translation_path + 'errors.title_minlength') }
      if (this.$v.account_title.titleUnique === false) { return this.$i18n.t(this.translation_path + 'errors.title_already_taken') }
      return ''
    },
    invalidAccountPercentageFeedback () {
      return this.$v.account_percentage.$error ? this.$i18n.t('common.errors.numeric') : ''
    }
  },
  validations: {
    account_title: {
      required,
      minLength: minLength(2),
      titleUnique: isTitleUnique()
    },
    form: ['account_title']
  },
  methods: {
    async validate () {
      this.$v.form.$touch()
      const self = this
      let isValid = !this.$v.form.$invalid

      const postData = {
        title: this.account_title,
        default_active_percentage: this.default_active_percentage,
        only_validate: true,
        type: this.typeOfAccount,
        id: this.account_id
      }

      // Validate that title is unique on create
      if (isValid) {
        const sApiEndpoint = process.env.VUE_APP_ROOT_API + '/accounts' + (this.isUpdate ? '/' + this.account_id : '')
        const sApiMethod = this.isUpdate ? 'put' : 'post'

        // Validate that code is unique on create
        await axios({
          method: sApiMethod,
          url: sApiEndpoint,
          data: postData
        })
          .catch(function (error) {
            if (error.response.status === 422) {
              isValid = false
              self.is_title_unique = false
            }
          })
      }

      if (isValid) {
        const data = {
          account_title: this.account_title,
          account_percentage: this.account_percentage
        }
        this.$emit('on-validate', data)
      }
      return isValid
    }
  },
  watch: {
    initialData: {
      handler: function (val) {
        if (val && Object.keys(val).length > 0) {
          this.account_title = Object.prototype.hasOwnProperty.call(val, 'title') && val.title ? val.title : null
          this.account_percentage = Object.prototype.hasOwnProperty.call(val, 'default_active_percentage') && val.default_active_percentage ? val.default_active_percentage : 0
          this.account_id = Object.prototype.hasOwnProperty.call(val, 'id') && val.id ? val.id : null
        }
      },
      immediate: true,
      deep: true
    },
    account_title () {
      this.is_title_unique = true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
