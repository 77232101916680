<template>
  <b-container class="create-account-wizard-liability create-account-wizard-liability-step-02">
    <b-row no-gutters>
      <b-col sm="12" md="5" class="detail-text text-left pt-3 pb-2 px-3 py-md-5 px-md-4 py-lg-5 px-lg-5" align-self="start">
        <h2 class="text-dark">
          {{ $t( translation_path + 'title_account') }}
        </h2>
        <i18n :path=" translation_path + 'intro_p1'" tag="p" class="text-gray">
          <b-link slot="link" target="_blank" to="/reports/other/account-plan-v2/liabilities">{{ $t('assets_liabilities.create_account_wizard.common.account_plan') }}</b-link>
        </i18n>
      </b-col>
      <b-col sm="12" md="7" class="accounts_wrapper pt-0 pb-2 px-3 py-md-5 px-md-4 py-lg-5 px-lg-5">
        <template v-if="accounts.length === 0">
          <b-alert show variant="danger">
            <h3>{{ $t(translation_path + 'no_category_heading') }}</h3>
            <i18n :path="translation_path + 'no_category_text'" tag="p">
              <b-link slot="link" target="_blank" :to="'/assets-liabilities/accounts/groups/create/liability'">{{ $t('assets_liabilities.create_account_wizard.common.no_category_link_text') }}</b-link>
            </i18n>
          </b-alert>
        </template>
        <template v-else>
          <h4 class="d-block d-md-none tableHeading">{{ this.$t(this.translation_path + (this.currentUser.strict_accounting_mode ? 'select_title_account' : 'select_title_category')) }}</h4>
          <b-table ref="categoryTable" class="spirecta-simple-table accounts-table spirecta-table-where-row-is-selected" show-empty hover responsive striped
                  :items="accounts"
                  :fields="fields"
                  selectable
                  select-mode="single"
                  @row-selected="rowSelected"
                  stacked="md"
            >
            <template v-slot:cell(title)="row">
              <!-- `data.value` is the value after formatted by the Formatter -->
              <b-link @click="$refs.categoryTable.selectRow(row.index)">{{ row.value | displayAccountTitle( row.item.code, currentCOA.prefix_account_title_with_code ) }}</b-link>
            </template>
          </b-table>
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import EventBus from '@/event-bus'
import displayAccountTitle from '@/assets/filters/displayAccountTitle'

export default {
  name: 'LiabilityAccountWizardStep02',
  props: ['initialData', 'typeOfAccount'],
  data () {
    return {
      accounts: [],
      translation_path: 'assets_liabilities.create_account_wizard.liabilities.step2.',
      rowSelectedData: []
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    local () {
      return this.currentUser.default_lang
    },
    fields () {
      return [{ title: { label: this.$t(this.translation_path + (this.currentUser.strict_accounting_mode ? 'select_title_account' : 'select_title_category')), sortable: true } }]
    },
    intro_p2_path () {
      return this.translation_path + (this.currentUser.strict_accounting_mode ? 'intro_p2_account' : 'intro_p2_category')
    },
    helpPageLink () {
      switch (this.$i18n.locale) {
        case 'sv':
          return '5e44a94504286364bc9542d8'
        case 'da':
          return '5dd7958c2c7d3a7e9ae4493d'
        default:
          return '5dd792af04286364bc9210fb'
      }
    }
  },
  async created () {
    if (this.$route.name !== 'liability-accounts-edit') {
      const accountType = this.typeOfAccount ? this.typeOfAccount : 'liability'
      axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups?account_type=${accountType}`)
        .then(response => response.data.data)
        .then(data => {
          this.accounts = data.sort(function (a, b) {
            const nameA = a.title
            const nameB = b.title
            return nameA.localeCompare(nameB, this.local)
          }.bind(this))
        })
        .catch(error => console.error(error))
    }
    EventBus.$emit('group-selected', (this.rowSelectedData).length > 0)
  },
  methods: {
    rowSelected (items) {
      this.rowSelectedData = items
      EventBus.$emit('group-selected', (this.rowSelectedData).length > 0)
      this.$emit('on-validate', { selected_account: items[0] })
    },
    validate () {
      return (this.rowSelectedData).length > 0
    },
    selectRow (itemIndex) {
      const selectedIndex = (this.accounts).findIndex(function (item) {
        return item.id === itemIndex.id
      })
      setTimeout(function () {
        this.$refs.categoryTable.selectRow(selectedIndex)
      }.bind(this), 1000)
    }
  },
  watch: {
    initialData: {
      handler: function (val) {
        if (val && Object.keys(val).length > 0) {
          const accountType = val.type
          axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups?account_type=${accountType}`)
            .then(response => response.data.data)
            .then(data => {
              this.accounts = data.sort(function (a, b) {
                const nameA = a.title
                const nameB = b.title
                return nameA.localeCompare(nameB, this.local)
              }.bind(this))
              this.selectRow(val.account_group)
            })
            .catch(error => console.error(error))
        }
      },
      immediate: true,
      deep: true
    }
  },
  filters: {
    displayAccountTitle
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/components/spirecta-table-where-row-is-selected.scss';
.create-account-wizard-liability-step-02 {

}
</style>
