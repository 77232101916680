<template>
  <b-container class="create-account-wizard-liability create-account-wizard-liability-step-02">
    <b-row no-gutters>
      <b-col sm="12" md="5" class="detail-text text-left pt-3 pb-2 px-3 py-md-5 px-md-4 py-lg-5 pl-lg-1 pr-lg-3" align-self="start">
        <h2>
          {{ $t( translation_path + ( currentUser.strict_accounting_mode ? 'title_account' : 'title_category') ) }}
        </h2>
        <p>
          {{ $t( translation_path + ( currentUser.strict_accounting_mode ? 'intro_p1_account' : 'intro_p1_category' )) }}
        </p>
        <i18n :path="intro_p2_path" tag="p">
          <b-link slot="help_pages" href="javascript:void(0);" :data-beacon-article-sidebar="helpPageLink">{{ $t('common.help_pages') }}</b-link>
          <b-link slot="account_groups_overview" target="_blank" to="/incomes-expenses/accounts/groups">{{ $t(translation_path + ( currentUser.strict_accounting_mode ? 'accounts_overview' : 'category_overview')) }}</b-link>
        </i18n>
      </b-col>
      <b-col sm="12" md="7" class="accounts_wrapper pt-0 pb-2 px-3 py-md-5 px-md-4 py-lg-5 pl-lg-5 pr-lg-3">
        <b-table ref="categoryTable" class="spirecta-simple-table accounts-table depth-1" show-empty hover responsive striped
                 :items="accounts"
                 :fields="fields"
                 selectable
                 select-mode="single"
                 @row-selected="rowSelected"
                 stacked="md"
          >
          <template v-slot:cell(title)="row">
            <!-- `data.value` is the value after formatted by the Formatter -->
            <b-link @click="$refs.categoryTable.selectRow(row.index)">{{ row.value | displayAccountTitle( row.item.code, currentCOA.prefix_account_title_with_code ) }}</b-link>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import LiabilityAccountWizardStep02 from '@/views/assets-liabilities/accounts/wizard-steps/liability/LiabilityAccountWizardStep02'
export default {
  name: 'LiabilityAccountWizardStep02',
  extends: LiabilityAccountWizardStep02
}
</script>

<style lang="scss">
  .create-account-wizard-liability-step-02 {
    .accounts-table
    {
      thead tr th{
        background: white;
        padding:15px 0px 15px 10px;
        font-size: 125%;
      }
      thead tr th h2{
        margin:0;
      }
      tbody{
        tr {
          &:hover td{
            background-color: #36b373;
            color:white;
            a {
              color: #fff;
            }
          }
          &.b-table-row-selected
          {
            background: #36b373 !important;
            color:#fff;
            a {
              color: #fff;
            }
          }
        }
        td {
          padding: 10px 10px;
          border-top:1px dashed #CACACA;
          vertical-align: middle;
        }
        tr:nth-child(odd){
          background: #fefeff;
        }
        tr:nth-child(even){
          background: #fefeff;
        }
        // Add left padding to first column
        td:nth-child(1){
          padding-left:20px;
        }
        // Make important column bold
        td:nth-child(1){
          font-weight:700;
        }
        button {
          &.active{
            border: 2px solid #0065ff;
          }
        }
      }
    }
  }
</style>
